<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('account.my_docs.modal_title')"
          />
          <q-space />
          <q-btn
            flat
            round
            dense
            icon="close"
            color="red"
            v-close-popup
            @click="clearData()"
          />
        </q-card-section>
        <q-separator class="q-mr-md q-ml-md" />
        <q-form @submit="submit()" greedy>
          <q-card-section class="q-pt-none q-mt-md">
            <div class="bg-yellow-5 default-rounded q-pa-sm">
              <ul v-if="numOfPartnerDocs != docTypes.length">
                <li v-html="$t('account.my_docs.tip_1')" />
                <li v-html="$t('account.my_docs.tip_2')" />
              </ul>
              <div v-else class="q-pt-sm q-pb-sm">
                <q-icon name="check" />
                <span v-html="$t('account.my_docs.no_docs')" />
              </div>
            </div>
            <div v-if="numOfPartnerDocs != docTypes.length">
              <div class="q-mt-md">
                <q-select
                  filled
                  v-model="option"
                  option-label="label"
                  option-value="value"
                  :options="docsList"
                />
              </div>
              <div class="q-mt-md">
                <q-file
                  filled
                  color="teal"
                  v-model="file"
                  :accept="mimes"
                  :disable="!option.value"
                  :label="$t('account.my_docs.select_file')"
                >
                  <template v-slot:prepend>
                    <q-icon name="cloud_upload" />
                  </template>
                </q-file>
              </div>
              <div
                class="q-mt-md q-pa-md default-rounded bg-grey-3"
                v-if="
                  message.validation &&
                  formatMessage(message.validation[1]).length
                "
              >
                {{ message.validation && formatMessage(message.validation[1]) }}
              </div>
            </div>
          </q-card-section>
          <q-separator
            class="q-ma-md"
            v-if="numOfPartnerDocs != docTypes.length"
          />
          <q-card-actions
            align="right"
            v-if="numOfPartnerDocs != docTypes.length"
          >
            <q-btn
              unelevated
              color="dark"
              type="submit"
              :loading="in_request"
              :label="$t('global.send')"
              class="default-rounded text-weight-regular"
            />
            <q-btn
              flat
              unelevated
              color="dark"
              v-close-popup
              @click="clearData()"
              :label="$t('global.cancel')"
              class="default-rounded text-weight-regular"
            />
          </q-card-actions>
        </q-form>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { notifyError, notifySuccess } from "@/shared/helpers/notify";
import { useGetters, useActions } from "vuex-composition-helpers";
import { fetchUploadFileToS3 } from "@/shared/helpers/s3Helpers";
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";

export default {
  name: "UploadDocumentComponent",

  props: {
    numOfPartnerDocs: {
      type: Number,
      default: 0,
    },
    documents: {
      type: Array,
      default: () => [],
    },
    docsList: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["uploaded"],

  setup(props, { emit }) {
    const { t } = useI18n();

    const option = ref({ value: "", label: t("account.my_docs.select") }),
      file = ref(null),
      mimes = ref(""),
      message = ref(""),
      in_request = ref(false);

    const { docTypes, userId, getTemporaryUploadLinks } = useGetters({
        docTypes: "account/getDocTypes",
        userId: "auth/userPartnerId",
        getTemporaryUploadLinks: "missions/getTemporaryUploadLinks",
      }),
      { fetchUploadDoc, fetchUserAction, fetchTemporaryUploadLinks } =
        useActions({
          fetchUploadDoc: "account/fetchUploadDoc",
          fetchUserAction: "auth/fetchUserAction",
          fetchTemporaryUploadLinks: "missions/fetchTemporaryUploadLinks",
        });

    const validExtensions = (validationStr) => {
      if (validationStr) {
        const regex = /mimes:([a-z,]+)/,
          match = validationStr.match(regex);

        if (match) {
          const extensions = match[1].split(",");
          return extensions.map((ext) => "." + ext.trim()).join(", ");
        }
      }
      return "";
    };

    const formatMessage = (maxStr) => {
      const regex = /max:(\d+)/,
        match = maxStr.match(regex);

      if (match) {
        const maxSize = parseInt(match[1]),
          maxSizeMB = maxSize / 1024;
        return t("account.my_docs.message", { value: maxSizeMB.toFixed(1) });
      }
      return "";
    };

    const submit = () => {
      if (!file.value) return;

      const payload = {
        valid: 0,
        observation: "",
        partner_id: userId.value,
        slug_doc: option.value.slug,
      };

      in_request.value = true;
      const fileAttributes = getFileAttributes(file.value);

      fetchTemporaryUploadLinks(fileAttributes)
        .then(() => {
          fetchUploadFileToS3(
            getTemporaryUploadLinks.value.url_pre_signed,
            file.value,
            fileAttributes
          ).then(() => {
            payload.doc_url = getTemporaryUploadLinks.value.url;
            fetchUploadDoc(payload)
              .then(async () => {
                notifySuccess(t("account.my_docs.success"));
                await fetchUserAction();
                clearData();
              })
              .finally(() => (in_request.value = false));
          });
        })
        .catch((error) => console.log(error));
    };

    const clearData = () => {
      const currentStep = localStorage.getItem("hasPendDoc");
      if (currentStep == 2) window.location.reload(); // if has_pending_docs is 2, go to analyzing page
      option.value = { value: "", label: t("account.my_docs.select") };
      file.value = null;
      mimes.value = "";
      message.value = "";
      emit("uploaded");
    };

    const defineFileType = (file) => {
      return file.type.split("/")[0];
    };

    const getFileAttributes = (file) => {
      const file_size = file.size,
        extension = file.name.split(".").pop(),
        file_type = defineFileType(file);

      return {
        file_type,
        file_size,
        extension,
        module: "partner_doc",
      };
    };

    watch(
      () => option.value,
      (newValue) => {
        if (newValue.value) {
          const docType = docTypes.value.find(
            (doc) => doc.id == newValue.value
          );
          message.value = JSON.parse(docType.validation);
          mimes.value = validExtensions(docType.validation);
        }
      }
    );

    return {
      userId,
      file,
      submit,
      mimes,
      option,
      message,
      docTypes,
      in_request,
      formatMessage,
      validExtensions,
      rejected: () => notifyError(formatMessage(message.value.validation[1])),
    };
  },
};
</script>

<style scoped>
.form {
  width: 600px;
}

@media screen and (max-width: 700px) {
  .form {
    width: 100%;
  }
}
</style>
